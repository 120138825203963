@function calUni($e) {
    @return ($e / 16px)+rem;
}
/*  
======================================== 

Color variables

Color palette
#E05400 - Trinidad
#3D507B - East Bay
    
========================================  
*/
$clr-trinidad: #E05400;
$clr-eastbay: #3D507B;
$clr-bondi : #00A0B3;
$clr-pastel: #8BE386;
$clr-bluewood: #2F4858;
$clr-kabul: #55433B;
$clr-foam : #C4FCF0;
$clr-wah: #ffffff;
$clr-dark: #324B4F;

/*  
======================================== 

Font family
    
========================================  
*/
$font-heading: 'Nunito', sans-serif;
$font-para: 'Roboto', sans-serif;
$font-writing: 'Satisfy', cursive;