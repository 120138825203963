/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
background: $clr-wah; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: rgba($clr-eastbay, 0.2); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: rgba($clr-eastbay, 0.8); 
}

.hidden-mobile {
    @include m-breakpoint-down(large) {
        transform: translateX(-14rem);
    }
}

.active-mobile {
    @include m-breakpoint-down(large) {
        transform: translateX(0);
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2955;
    background-color: $clr-wah;
    width: calUni(220px);
    height: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);

    &--overlay {
        transition: all 200ms ease-in;
    }

    &--overlay.active {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2500;
        background-color: #33333390;
        overflow-y: hidden;
    }

    &--wrapper {
        margin-top: calUni(70px);
        padding: 20px 10px 20px 20px;
    }

    &--header{
        display: flex;
        justify-content: space-between;

        .toggle-sideContent {
            color: $clr-dark;
        }
    }

    &--category {

        .category-list {
            color: $clr-kabul;
            font-size: 1.125rem;
            font-family: $font-heading;
            font-weight: 600;
            list-style-type: none;
            transition: all 200ms ease-out;


            .category-item {
                margin: 5px 0;
                border-radius: 5px;

                transition: all 100ms ease-out;

                &:hover{
                    background-color: rgba($clr-dark, $alpha: 0.2);
                }
                a {
                    display: block;
                    width: 100%;
                    padding: 1.5px 20px;
                    cursor: pointer;
                }
            }

            .category-item.active {
                background-color: $clr-bondi;
                color: $clr-wah;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            }
        }
    }

    .section-name {
        color: $clr-kabul;
    }

    &--toggle {
        position: fixed;
        z-index: 2955;
        top: calUni(180px);
        left: 0px;
        color: $clr-wah;
        background-color: $clr-trinidad;
        padding: 5px 15px;
        border-radius: 0 10px 10px 0px;
        border: 1px solid $clr-wah;
        border-left: 0px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);


        &.active {
            left: calUni(220px);
        }
    }

    &--tag {
        .tag-list {
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            color: $clr-dark;

            .tag-item {
                background-color: $clr-foam;
                border-radius: 20px;
                font-size: calUni(14px);
                margin-right: 10px;
                margin-bottom: 10px;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 2px 10px;
                }
            }
        }
    }
}

.dark {
    .sidebar {
        background-color: $clr-dark;

        .section-name {
            color: $clr-foam;
        }

        &--header{
    
            .toggle-sideContent {
                color: $clr-wah;
            }
        }

        &--category {
            .category-list {
                color: $clr-wah;

                .category-item.active {
                    background-color: $clr-bondi;
                }
            }
        }

        &--toggle {
            background-color: $clr-dark;
            color: $clr-wah;
            border-color: $clr-wah;
        }
    }
}