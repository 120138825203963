.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3000;
    background-color: $clr-wah;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    .container {
        height: calUni(70px);
        justify-content: space-between;
        align-items: center;

        @include m-breakpoint-down(small) {
            height: 3.625rem;
        }
    }

    &--logo {
        width: calUni(145px);

        @include m-breakpoint-down(small) {
            width: 6.0625rem;
        }
    }

    &--content {
        .button-wrapper {
            @include flex-center;

            
        }
        .nav-btn {
            font-size: 1.75rem;
            color: $clr-bluewood;
            margin: 0 calUni(16px);

            &:nth-child(1){
                font-size: 1.365rem;
            }

            &:nth-child(3){
                margin-right: 0;
            }
        }
    }

    .search-hidden {

        @include m-breakpoint-down(small){
            opacity: 0;
            visibility: hidden;
            transform: translateX(-50%) translateY(-30px);
        }
        
    }

    .search-active {
        .overlay {
            position: absolute;
            width: 135%;
            height: 65px;
            top: 50%;
            background: $clr-dark;
            opacity: 0.4;
            left: 50%;
            z-index: -1;
            transform: translate(-50%, -50%);
            transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
            display: none;

            @include m-breakpoint-down(small) {
                display: block;
            }
        }

        @include m-breakpoint-down(small) {
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%) translateY(0px);
        }
        
    }

    &--form {
        display: flex;
        justify-content: center;
        align-items: center;

        width: calUni(527px);
        height: calUni(40px);
        position: relative;
        margin: 0 16px;
        transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);

        @include m-breakpoint-down(large){
            width: 17.9375rem;
        }

        @include m-breakpoint-down(small){
            position: absolute;
            left: 50%;
            top: 65px;
            transform: translateX(-50%);
            margin: 0;
        }

        .auto-complete {
            list-style-type: none;
            position: absolute;
            top: calUni(40px);
            left: 0;
            
            li {
                background-color: $clr-bondi;
                color: $clr-wah;
                padding: 5px 10px;
            }
        }

        input {
            width: 100%;
            height: 100%;
            border: 1px solid $clr-eastbay;
            border-radius: 3px;
            outline: none;
            transition: all 200ms ease;
            font-family: $font-heading;
            text-indent: 5px;
            color: $clr-eastbay;

            &:focus {
                border-color: $clr-trinidad;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: calUni(50px);
            height: 100%;
            color: $clr-wah;
            background-color: $clr-eastbay;
            border-radius: 0px 3px 3px 0px;
        }
    }
}

.menuOption {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    background-color: $clr-wah;
    right: 10px;
    top: calUni(50px);
    transition: all 200ms ease-in-out;
    padding: calUni(10px) 15px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    display: flex;
    flex-direction: column;

    @include m-breakpoint-up(slarge) {
        right: 250px;
    }

    button {
        font-family: $font-heading;
        padding: 5px 10px;
        color: $clr-dark;
        transition: all 200ms ease;
        &:hover {
            color: $clr-trinidad;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        }
    }

    button:not(:last-child){
        margin-bottom: 10px;
    }
    
}

.dark {
    .menuOption {
        background-color: $clr-dark;
        
        button {
            color: $clr-wah;

            &:hover {
                color:$clr-trinidad;
            }
        }
    }
}

.nav-menu-btn:hover + .menuOption {
    visibility: visible;
    opacity: 1;
}

.menuOption:hover {
    visibility: visible;
    opacity: 1;
}

input::-webkit-calendar-picker-indicator {
    display: none !important;
}

.menuModal {
    
    &--wrapper {
        overflow: auto;
        transition: all 200ms ease-in;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($clr-dark, 0.8);

        @include flex-center;

        @include m-breakpoint-down(small) {
            align-items: flex-start;
        }

        .modal {
            background-color: $clr-wah;
            width: 80%;
            min-height:80%;
            border-radius: 5px;
            
            @include m-breakpoint-down(small) {
                width: 100%;
                min-height: 100%;
                border-radius: 0px;
            }

            &--header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 20px;
                background-color: $clr-bluewood;
                border-radius: 5px 5px 0 0;

                @include m-breakpoint-down(small) {
                    border-radius: 0px;
                }

                h1 {
                    font-family: $font-heading;
                    font-weight: 800;
                    color: $clr-trinidad;
                    font-size: 1.765rem;
                }

                button {
                    color: $clr-wah;
                    padding: 10px;
                    transition: color 100ms ease;

                    &:hover {
                        color: $clr-trinidad;
                    }
                }
            }

            &--desc {
                display: flex;
                align-items: flex-start;
                height: auto;
                color: $clr-dark;
                margin-top: 20px;

                .desc-left {
                    flex: 1;
                }

                .desc-right {
                    flex: 1;
                }

                b {
                    color: $clr-trinidad;
                }

                h1 {
                    font-family: $font-heading;
                }

                a {
                    margin: 10px;
                }

                @include m-breakpoint-down(lmedium) {
                    flex-direction: column-reverse;
                }

                p {
                    padding: 15px 15px 0 0;
                    font-size: 1.256rem;
                    text-align: justify;

                    @include m-breakpoint-down(small) {
                        font-size: 1rem;
                    }
                }
                
                code {
                    display: inline-block;
                    font-size: 1rem;
                    margin: 15px 0 15px 0;
                    background-color: $clr-trinidad;
                    color: $clr-wah;
                    padding: 5px 10px;
                    background: rgb(224,84,0);
                    background: linear-gradient(360deg, rgba(224,84,0,1) 0%, rgba(255,138,67,1) 100%);
                    border-radius: 5px;

                    @include m-breakpoint-down(small) {
                        font-size: 0.8rem;
                    }
                }

                .image {
                    width: 500px;
                    margin: auto;
                }
            }
        }
    }

}

.dark {
    .navbar {
        background-color: $clr-dark;

        &--content {
            .nav-btn {
                color: $clr-wah;
            }
        }

        &--form {
            input {
                border-color: $clr-bondi;
            }
            button {
                background-color: $clr-bondi;
            }
        }
    }

    .menuModal {
        &--wrapper {
            .modal {
                border: 1px solid $clr-wah;
                background-color: $clr-bluewood;
    
                &--desc {
                    color: $clr-wah;
                }
            }
        }
        
    }
}
  