@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,800&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

/*  
======================================== 

CSS Reset
    
========================================  
*/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Loading */
@keyframes ldio-svfroiph48k {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.12, 1.12);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.ldio-svfroiph48k div > div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e05400;
  animation: ldio-svfroiph48k 1s linear infinite;
}
.ldio-svfroiph48k div:nth-child(1) > div {
  left: 68px;
  top: 44px;
  animation-delay: -0.8333333333333334s;
}
.ldio-svfroiph48k > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 74px 50px;
}
.ldio-svfroiph48k div:nth-child(2) > div {
  left: 56px;
  top: 65px;
  animation-delay: -0.6666666666666666s;
}
.ldio-svfroiph48k > div:nth-child(2) {
  transform: rotate(60deg);
  transform-origin: 62px 71px;
}
.ldio-svfroiph48k div:nth-child(3) > div {
  left: 32px;
  top: 65px;
  animation-delay: -0.5s;
}
.ldio-svfroiph48k > div:nth-child(3) {
  transform: rotate(120deg);
  transform-origin: 38px 71px;
}
.ldio-svfroiph48k div:nth-child(4) > div {
  left: 20px;
  top: 44px;
  animation-delay: -0.3333333333333333s;
}
.ldio-svfroiph48k > div:nth-child(4) {
  transform: rotate(180deg);
  transform-origin: 26px 50px;
}
.ldio-svfroiph48k div:nth-child(5) > div {
  left: 32px;
  top: 23px;
  animation-delay: -0.16666666666666666s;
}
.ldio-svfroiph48k > div:nth-child(5) {
  transform: rotate(240deg);
  transform-origin: 38px 29px;
}
.ldio-svfroiph48k div:nth-child(6) > div {
  left: 56px;
  top: 23px;
  animation-delay: 0s;
}
.ldio-svfroiph48k > div:nth-child(6) {
  transform: rotate(300deg);
  transform-origin: 62px 29px;
}
.loadingio-spinner-spin-zvuwi6ogw3s {
  width: 64px;
  height: 64px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-svfroiph48k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.64);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-svfroiph48k div {
  box-sizing: content-box;
}
/*  
======================================== 

Utility classes
    
========================================  
*/

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.visible-none {
  opacity: 0 !important;
  visibility: hidden !important;
}

.visible-visible {
  opacity: 1;
  visibility: visible;
}

.hide-on-mobile {
  @include m-breakpoint-down(large) {
    display: none !important;
  }
}

.hide-on-desktop {
  @include m-breakpoint-up(xlarge) {
    display: none;
  }
}

.hide-on-small {
  @include m-breakpoint-down(small) {
    display: none !important;
  }
}
.only-on-small {
  @include m-breakpoint-up(medium) {
    display: none;
  }
}

.mr-0 {
  margin-right: 0 !important;
}
/*  
======================================== 

Apply theme
    
========================================  
*/

.container {
  padding: 0 calUni(20px);
  max-width: calUni(1440px);
  margin: auto;
}

body {
  font-family: $font-para;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  padding: 0;
  margin: 0;
}
