/*  
======================================== 

Color variables

Color palette
#E05400 - Trinidad
#3D507B - East Bay

========================================  
*/
/*  
======================================== 

Font family

========================================  
*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,800&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@-webkit-keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes slide_left {
  from {
    transform: translateX(30px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide_left {
  from {
    transform: translateX(30px);
  }
  to {
    transform: translateX(0);
  }
}
/*  
======================================== 

CSS Reset

========================================  
*/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
*::before,
*::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* Loading */
@-webkit-keyframes ldio-svfroiph48k {
  0% {
    opacity: 1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: translateZ(0) scale(1.12, 1.12);
  }
  100% {
    opacity: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
@keyframes ldio-svfroiph48k {
  0% {
    opacity: 1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: translateZ(0) scale(1.12, 1.12);
  }
  100% {
    opacity: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.ldio-svfroiph48k div > div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e05400;
  -webkit-animation: ldio-svfroiph48k 1s linear infinite;
          animation: ldio-svfroiph48k 1s linear infinite;
}

.ldio-svfroiph48k div:nth-child(1) > div {
  left: 68px;
  top: 44px;
  -webkit-animation-delay: -0.8333333333s;
          animation-delay: -0.8333333333s;
}

.ldio-svfroiph48k > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 74px 50px;
}

.ldio-svfroiph48k div:nth-child(2) > div {
  left: 56px;
  top: 65px;
  -webkit-animation-delay: -0.6666666667s;
          animation-delay: -0.6666666667s;
}

.ldio-svfroiph48k > div:nth-child(2) {
  transform: rotate(60deg);
  transform-origin: 62px 71px;
}

.ldio-svfroiph48k div:nth-child(3) > div {
  left: 32px;
  top: 65px;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.ldio-svfroiph48k > div:nth-child(3) {
  transform: rotate(120deg);
  transform-origin: 38px 71px;
}

.ldio-svfroiph48k div:nth-child(4) > div {
  left: 20px;
  top: 44px;
  -webkit-animation-delay: -0.3333333333s;
          animation-delay: -0.3333333333s;
}

.ldio-svfroiph48k > div:nth-child(4) {
  transform: rotate(180deg);
  transform-origin: 26px 50px;
}

.ldio-svfroiph48k div:nth-child(5) > div {
  left: 32px;
  top: 23px;
  -webkit-animation-delay: -0.1666666667s;
          animation-delay: -0.1666666667s;
}

.ldio-svfroiph48k > div:nth-child(5) {
  transform: rotate(240deg);
  transform-origin: 38px 29px;
}

.ldio-svfroiph48k div:nth-child(6) > div {
  left: 56px;
  top: 23px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.ldio-svfroiph48k > div:nth-child(6) {
  transform: rotate(300deg);
  transform-origin: 62px 29px;
}

.loadingio-spinner-spin-zvuwi6ogw3s {
  width: 64px;
  height: 64px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-svfroiph48k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.64);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-svfroiph48k div {
  box-sizing: content-box;
}

/*  
======================================== 

Utility classes

========================================  
*/
.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.visible-none {
  opacity: 0 !important;
  visibility: hidden !important;
}

.visible-visible {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 85.3125em) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 85.375em) {
  .hide-on-desktop {
    display: none;
  }
}

@media (max-width: 35.9375em) {
  .hide-on-small {
    display: none !important;
  }
}

@media (min-width: 36em) {
  .only-on-small {
    display: none;
  }
}

.mr-0 {
  margin-right: 0 !important;
}

/*  
======================================== 

Apply theme

========================================  
*/
.container {
  padding: 0 1.25rem;
  max-width: 90rem;
  margin: auto;
}

body {
  font-family: "Roboto", sans-serif;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  padding: 0;
  margin: 0;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3000;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.navbar .container {
  height: 4.375rem;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 35.9375em) {
  .navbar .container {
    height: 3.625rem;
  }
}
.navbar--logo {
  width: 9.0625rem;
}
@media (max-width: 35.9375em) {
  .navbar--logo {
    width: 6.0625rem;
  }
}
.navbar--content .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar--content .nav-btn {
  font-size: 1.75rem;
  color: #2F4858;
  margin: 0 1rem;
}
.navbar--content .nav-btn:nth-child(1) {
  font-size: 1.365rem;
}
.navbar--content .nav-btn:nth-child(3) {
  margin-right: 0;
}
@media (max-width: 35.9375em) {
  .navbar .search-hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) translateY(-30px);
  }
}
.navbar .search-active .overlay {
  position: absolute;
  width: 135%;
  height: 65px;
  top: 50%;
  background: #324B4F;
  opacity: 0.4;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  display: none;
}
@media (max-width: 35.9375em) {
  .navbar .search-active .overlay {
    display: block;
  }
}
@media (max-width: 35.9375em) {
  .navbar .search-active {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0px);
  }
}
.navbar--form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32.9375rem;
  height: 2.5rem;
  position: relative;
  margin: 0 16px;
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media (max-width: 85.3125em) {
  .navbar--form {
    width: 17.9375rem;
  }
}
@media (max-width: 35.9375em) {
  .navbar--form {
    position: absolute;
    left: 50%;
    top: 65px;
    transform: translateX(-50%);
    margin: 0;
  }
}
.navbar--form .auto-complete {
  list-style-type: none;
  position: absolute;
  top: 2.5rem;
  left: 0;
}
.navbar--form .auto-complete li {
  background-color: #00A0B3;
  color: #ffffff;
  padding: 5px 10px;
}
.navbar--form input {
  width: 100%;
  height: 100%;
  border: 1px solid #3D507B;
  border-radius: 3px;
  outline: none;
  transition: all 200ms ease;
  font-family: "Nunito", sans-serif;
  text-indent: 5px;
  color: #3D507B;
}
.navbar--form input:focus {
  border-color: #E05400;
}
.navbar--form button {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.125rem;
  height: 100%;
  color: #ffffff;
  background-color: #3D507B;
  border-radius: 0px 3px 3px 0px;
}

.menuOption {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  background-color: #ffffff;
  right: 10px;
  top: 3.125rem;
  transition: all 200ms ease-in-out;
  padding: 0.625rem 15px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 120em) {
  .menuOption {
    right: 250px;
  }
}
.menuOption button {
  font-family: "Nunito", sans-serif;
  padding: 5px 10px;
  color: #324B4F;
  transition: all 200ms ease;
}
.menuOption button:hover {
  color: #E05400;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.menuOption button:not(:last-child) {
  margin-bottom: 10px;
}

.dark .menuOption {
  background-color: #324B4F;
}
.dark .menuOption button {
  color: #ffffff;
}
.dark .menuOption button:hover {
  color: #E05400;
}

.nav-menu-btn:hover + .menuOption {
  visibility: visible;
  opacity: 1;
}

.menuOption:hover {
  visibility: visible;
  opacity: 1;
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

.menuModal--wrapper {
  overflow: auto;
  transition: all 200ms ease-in;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(50, 75, 79, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 35.9375em) {
  .menuModal--wrapper {
    align-items: flex-start;
  }
}
.menuModal--wrapper .modal {
  background-color: #ffffff;
  width: 80%;
  min-height: 80%;
  border-radius: 5px;
}
@media (max-width: 35.9375em) {
  .menuModal--wrapper .modal {
    width: 100%;
    min-height: 100%;
    border-radius: 0px;
  }
}
.menuModal--wrapper .modal--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2F4858;
  border-radius: 5px 5px 0 0;
}
@media (max-width: 35.9375em) {
  .menuModal--wrapper .modal--header {
    border-radius: 0px;
  }
}
.menuModal--wrapper .modal--header h1 {
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  color: #E05400;
  font-size: 1.765rem;
}
.menuModal--wrapper .modal--header button {
  color: #ffffff;
  padding: 10px;
  transition: color 100ms ease;
}
.menuModal--wrapper .modal--header button:hover {
  color: #E05400;
}
.menuModal--wrapper .modal--desc {
  display: flex;
  align-items: flex-start;
  height: auto;
  color: #324B4F;
  margin-top: 20px;
}
.menuModal--wrapper .modal--desc .desc-left {
  flex: 1;
}
.menuModal--wrapper .modal--desc .desc-right {
  flex: 1;
}
.menuModal--wrapper .modal--desc b {
  color: #E05400;
}
.menuModal--wrapper .modal--desc h1 {
  font-family: "Nunito", sans-serif;
}
.menuModal--wrapper .modal--desc a {
  margin: 10px;
}
@media (max-width: 63.9375em) {
  .menuModal--wrapper .modal--desc {
    flex-direction: column-reverse;
  }
}
.menuModal--wrapper .modal--desc p {
  padding: 15px 15px 0 0;
  font-size: 1.256rem;
  text-align: justify;
}
@media (max-width: 35.9375em) {
  .menuModal--wrapper .modal--desc p {
    font-size: 1rem;
  }
}
.menuModal--wrapper .modal--desc code {
  display: inline-block;
  font-size: 1rem;
  margin: 15px 0 15px 0;
  background-color: #E05400;
  color: #ffffff;
  padding: 5px 10px;
  background: rgb(224, 84, 0);
  background: linear-gradient(360deg, rgb(224, 84, 0) 0%, rgb(255, 138, 67) 100%);
  border-radius: 5px;
}
@media (max-width: 35.9375em) {
  .menuModal--wrapper .modal--desc code {
    font-size: 0.8rem;
  }
}
.menuModal--wrapper .modal--desc .image {
  width: 500px;
  margin: auto;
}

.dark .navbar {
  background-color: #324B4F;
}
.dark .navbar--content .nav-btn {
  color: #ffffff;
}
.dark .navbar--form input {
  border-color: #00A0B3;
}
.dark .navbar--form button {
  background-color: #00A0B3;
}
.dark .menuModal--wrapper .modal {
  border: 1px solid #ffffff;
  background-color: #2F4858;
}
.dark .menuModal--wrapper .modal--desc {
  color: #ffffff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(61, 80, 123, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(61, 80, 123, 0.8);
}

@media (max-width: 85.3125em) {
  .hidden-mobile {
    transform: translateX(-14rem);
  }
}

@media (max-width: 85.3125em) {
  .active-mobile {
    transform: translateX(0);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2955;
  background-color: #ffffff;
  width: 13.75rem;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
}
.sidebar--overlay {
  transition: all 200ms ease-in;
}
.sidebar--overlay.active {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2500;
  background-color: rgba(51, 51, 51, 0.5647058824);
  overflow-y: hidden;
}
.sidebar--wrapper {
  margin-top: 4.375rem;
  padding: 20px 10px 20px 20px;
}
.sidebar--header {
  display: flex;
  justify-content: space-between;
}
.sidebar--header .toggle-sideContent {
  color: #324B4F;
}
.sidebar--category .category-list {
  color: #55433B;
  font-size: 1.125rem;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  list-style-type: none;
  transition: all 200ms ease-out;
}
.sidebar--category .category-list .category-item {
  margin: 5px 0;
  border-radius: 5px;
  transition: all 100ms ease-out;
}
.sidebar--category .category-list .category-item:hover {
  background-color: rgba(50, 75, 79, 0.2);
}
.sidebar--category .category-list .category-item a {
  display: block;
  width: 100%;
  padding: 1.5px 20px;
  cursor: pointer;
}
.sidebar--category .category-list .category-item.active {
  background-color: #00A0B3;
  color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.sidebar .section-name {
  color: #55433B;
}
.sidebar--toggle {
  position: fixed;
  z-index: 2955;
  top: 11.25rem;
  left: 0px;
  color: #ffffff;
  background-color: #E05400;
  padding: 5px 15px;
  border-radius: 0 10px 10px 0px;
  border: 1px solid #ffffff;
  border-left: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
}
.sidebar--toggle.active {
  left: 13.75rem;
}
.sidebar--tag .tag-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  color: #324B4F;
}
.sidebar--tag .tag-list .tag-item {
  background-color: #C4FCF0;
  border-radius: 20px;
  font-size: 0.875rem;
  margin-right: 10px;
  margin-bottom: 10px;
}
.sidebar--tag .tag-list .tag-item a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 2px 10px;
}

.dark .sidebar {
  background-color: #324B4F;
}
.dark .sidebar .section-name {
  color: #C4FCF0;
}
.dark .sidebar--header .toggle-sideContent {
  color: #ffffff;
}
.dark .sidebar--category .category-list {
  color: #ffffff;
}
.dark .sidebar--category .category-list .category-item.active {
  background-color: #00A0B3;
}
.dark .sidebar--toggle {
  background-color: #324B4F;
  color: #ffffff;
  border-color: #ffffff;
}

.loading-content {
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: #E05400;
  font-size: 2.5rem;
  transition: all 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-content .loading-icon {
  -webkit-animation: spinning 1.5s linear infinite;
          animation: spinning 1.5s linear infinite;
}

.dark .loading-content {
  background-color: #324B4F;
}

.no-item-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  padding-top: 3rem;
  flex-direction: column;
}
.no-item-page h1 {
  font-family: "Nunito", sans-serif;
  color: #3D507B;
  font-weight: 700;
  margin-bottom: -40px;
  font-style: italic;
  font-size: 2.125rem;
}
.no-item-page img {
  width: 30%;
}
@media (max-width: 35.9375em) {
  .no-item-page img {
    width: 100%;
  }
}

.mainSection {
  width: 100%;
  padding-left: 13.75rem;
  padding-top: 4.375rem;
  background-color: #ffffff;
}
@media (max-width: 85.3125em) {
  .mainSection {
    padding-left: 0;
  }
}
@media (max-width: 35.9375em) {
  .mainSection {
    padding-top: 3.5rem;
  }
}
.mainSection--header {
  position: relative;
  padding: 0.655rem 0;
  background-color: rgba(196, 252, 240, 0.5);
  color: #3D507B;
}
.mainSection--header .title {
  font-family: "Nunito", sans-serif;
  font-weight: 800;
}
@media (max-width: 35.9375em) {
  .mainSection--header .title {
    font-size: 1.2rem;
    line-height: 1.2;
  }
}
.mainSection--header .description {
  color: #333;
  width: 50%;
}
@media (max-width: 85.3125em) {
  .mainSection--header .description {
    width: 70%;
  }
}
@media (max-width: 35.9375em) {
  .mainSection--header .description {
    width: 100%;
    font-size: 0.875rem;
  }
}
.mainSection--header .tagboard {
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: -10px;
  font-family: "Nunito", sans-serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: 0.8255rem;
  background-color: #ffffff;
  border: 1px solid #E05400;
  color: #E05400;
  padding: 5px 20px;
  border-radius: 10px;
  -webkit-animation: slide_left 200ms ease both;
          animation: slide_left 200ms ease both;
}
.mainSection--header .tagboard .close-tag {
  position: absolute;
  background-color: white;
  width: 30px;
  height: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 500px;
  color: red;
  right: -10px;
  top: -20px;
}
@media (max-width: 35.9375em) {
  .mainSection--header .tagboard {
    font-size: 0.5rem;
    bottom: -10px;
  }
}
.mainSection .card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media (max-width: 85.3125em) {
  .mainSection .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 35.9375em) {
  .mainSection .card-grid {
    grid-template-columns: repeat(1, 100%);
  }
}
.mainSection .card-grid .cardSection {
  height: 100%;
  min-height: 21.25rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  transition: all 200ms ease-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}
@media (max-width: 35.9375em) {
  .mainSection .card-grid .cardSection {
    min-height: 8.75rem;
    background-size: 100%;
  }
}
.mainSection .card-grid .cardSection--content {
  display: block;
  width: 100%;
  height: 100%;
}
.mainSection .card-grid .cardSection--image {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 115%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;
  margin-bottom: 0.75rem;
}
@media (max-width: 63.9375em) {
  .mainSection .card-grid .cardSection--image {
    height: 150px;
  }
}
@media (max-width: 35.9375em) {
  .mainSection .card-grid .cardSection--image {
    height: 120px;
  }
}
.mainSection .card-grid .cardSection--image .image-text {
  color: #ffffff;
  text-align: center;
  padding: 0 10px;
  font-family: "Satisfy", cursive;
}
.mainSection .card-grid .cardSection--image .tags {
  position: absolute;
  font-size: 1px;
  visibility: hidden;
}
.mainSection .card-grid .cardSection .info {
  padding: 0.9375rem;
}
.mainSection .card-grid .cardSection:hover {
  transform: translateY(-5px);
  background-color: #C4FCF0;
}
.mainSection .card-grid .cardSection:hover .cardSection--image {
  background-size: 120%;
}
.mainSection .card-grid .cardSection--name {
  font-family: "Nunito", sans-serif;
  color: #3D507B;
}
.mainSection .card-grid .cardSection--title {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #55433B;
}
.mainSection .card-grid .cardSection--desc {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #55433B;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dark {
  min-height: 100vh;
  background-color: #324B4F;
}
.dark .mainSection {
  background-color: #324B4F;
}
.dark .mainSection--header {
  background-color: #3D507B;
}
.dark .mainSection--header .title {
  color: #8BE386;
}
.dark .mainSection--header .description {
  color: #ffffff;
}
.dark .mainSection .card-grid .cardSection {
  background-color: #253a3d;
}
.dark .mainSection .card-grid .cardSection:hover {
  background-color: #005c85;
}
.dark .mainSection .card-grid .cardSection--name {
  color: #8BE386;
}
.dark .mainSection .card-grid .cardSection--title {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.dark .mainSection .card-grid .cardSection--desc {
  color: #ffffff;
}
.dark .no-item-page h1 {
  color: #8BE386;
}/*# sourceMappingURL=App.css.map */