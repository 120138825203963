@keyframes spinning {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes slide_left {
    from {
        transform: translateX(30px);
    }
    to {
        transform: translateX(0);
    }
}